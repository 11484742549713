import { useEffect, useId, useState, useRef, ChangeEvent, ClipboardEvent } from 'react';
import type { commonInputFieldProps } from '@/app/components/common/LoginPwdInput';

const LoginEmailInput = ({
                           labelText,
                           defaultValueText,
                           onChangeValidation,
                           disabled = false
}: commonInputFieldProps) => {

  const inputRef = useRef<HTMLInputElement>(null);
  const eleId = useId();
  const validationRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

  const [inputError, setInputError] = useState<string | null>();
  const [inputVal, setInputVal] = useState<string | null>();

  useEffect(() => {
    if (inputVal == null || inputVal.length == 0) {
      // Nothing to do when empty
      // } else if (inputVal.length === 0) {
      //   setInputError("Please enter email address")
      //   onChangeValidation && onChangeValidation(false, inputVal)
    } else if (!validationRegex.test(inputVal)) {
      setInputError('Please enter a valid email address');
      onChangeValidation && onChangeValidation(false, inputVal);
    } else {
      setInputError(null);
      onChangeValidation && onChangeValidation(true, inputVal);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputVal, onChangeValidation]);

  // [One-off] Assign input field default value (instead of 'defaultValue')
  useEffect(() => {
    if (inputRef.current && defaultValueText) {
      inputRef.current.value = defaultValueText;
    }
  }, [defaultValueText]);

  return (
    <div className='flex flex-1 flex-col items-stretch gap-y-1'>
      <label htmlFor={eleId} className='text-sm'>
        {labelText}
      </label>

      <input
        type='email'
        required
        id={eleId}
        name='email'
        ref={inputRef}
        placeholder='user@company.com'
        className={`w-full border-b border-gray-600 px-2 py-0.5 focus:bg-amber-100 ${inputError && 'bg-rose-100'}`}
        onChange={(e: ChangeEvent<HTMLInputElement>) => setInputVal(e.target.value.length == 0 ? null : e.target.value)}
        onPaste={(e: ClipboardEvent<HTMLInputElement>) => setInputVal(e.clipboardData.getData('text'))}
      />
      {inputError && <p className='text-sm text-red-600'>{inputError}</p>}
    </div>
  );
};
export default LoginEmailInput;
