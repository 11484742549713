import { useId, useState, useRef, useEffect, ChangeEvent, ClipboardEvent } from 'react';

export type commonInputFieldProps = {
  // HTML attributes
  defaultValueText?: string;
  labelText?: string;

  // Operation Setting
  shouldValidate?: boolean;
  disabled: boolean;

  // Interaction
  onChangeValidation?: (validFlag: boolean, inputTxt: string) => void;
};

const LoginPwdInput = ({
  defaultValueText,
  labelText,
  shouldValidate = false,
  disabled = false,
  onChangeValidation,
}: commonInputFieldProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const eleId = useId();
  const validationRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#@$£^/:!%*?&])[A-Za-z\d#@$£^/:!%*?&]{8,30}$/g;

  const [inputError, setInputError] = useState<string | null>();
  const [inputVal, setInputVal] = useState<string | null>();

  useEffect(() => {
    if (inputVal == null || inputVal.length == 0) {
      // Nothing to do
      onChangeValidation && onChangeValidation(false, '');
    } else if (shouldValidate && !validationRegex.test(inputVal)) {
      setInputError('Please enter a valid password');
      onChangeValidation && onChangeValidation(false, inputVal);
    } else {
      setInputError(null);
      onChangeValidation && onChangeValidation(true, inputVal);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputVal, onChangeValidation]);

  // [One-off] Assign input field default value (instead of 'defaultValue')
  useEffect(() => {
    if (inputRef.current && defaultValueText) {
      inputRef.current.value = defaultValueText;
    }
  }, [defaultValueText]);

  return (
    <div className='flex flex-1 flex-col items-stretch gap-y-0.5'>
      <label htmlFor={eleId} className='text-sm'>
        {labelText}
      </label>

      <input
        type='password'
        required
        id={eleId}
        name='password'
        ref={inputRef}
        placeholder='password'
        className={`w-full border-b border-gray-600 px-2 py-0.5 focus:bg-amber-100 ${inputError && 'bg-rose-100'}`}
        onChange={(e: ChangeEvent<HTMLInputElement>) => setInputVal(e.target.value.length == 0 ? null : e.target.value)}
        onPaste={(e: ClipboardEvent<HTMLInputElement>) => setInputVal(e.clipboardData.getData('text'))}
      />
      {inputError && <p className='text-sm text-red-600'>{inputError}</p>}
    </div>
  );
};
export default LoginPwdInput;
