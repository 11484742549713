// 'use client'

export default function SDAlert({
  isEnabled = true,
  idx = 0,
  title = 'Error',
  message = 'Unknown error',
  children,
  icon,
  primaryButtons,
  secondaryButtons,
}: {
  // Control
  isEnabled?: boolean;
  idx: number;

  // Content
  title?: string | null;
  message: string | null;
  children?: React.ReactNode;
  icon?: React.ReactNode;

  // Action button
  primaryButtons?: React.ReactNode;
  secondaryButtons?: React.ReactNode;
}) {
  if (!isEnabled) {
    return <></>;
  }

  return (
    <div className='rounded bg-yellow-50 px-4 py-3 shadow-lg'>
      <div className='flex flex-col'>
        <div className='flex flex-row items-start justify-start gap-2'>
          {icon && <div className='flex-initial'>{icon}</div>}

          <div>
            {title && <h3 className='mb-1 text-sm font-semibold text-slate-700'>{title}</h3>}
            <p>{message}</p>
          </div>
        </div>

        {/* Action Group */}
        {children && <div>{children}</div>}

        {(primaryButtons || secondaryButtons) && (
          <div className='flex flex-row-reverse items-center justify-between gap-2'>
            {/* Primary Button */}
            {primaryButtons && <div className='flex flex-initial flex-row gap-1'>{primaryButtons}</div>}

            {/* Secondary Button */}
            {secondaryButtons && <div className='grow-1 flex flex-row'>{secondaryButtons}</div>}
          </div>
        )}
      </div>
    </div>
  );
}
