import { AxiosError, AxiosResponse } from 'axios';
import axiosShared, { API_URL } from './api';
import { JWTToken, isLoggedIn, setUserToken } from '../lib/storageCommon';

export interface ApiResponse<T> {
  success: boolean;
  data?: T;
  error?: string;
}
// export class ApiError extends Error {
// }

//#region JWT Cookie related
export async function apiLoginWithCookie(email: string, password: string): Promise<ApiResponse<any>> {
  // Guard
  if (email === '' || password === '') {
    return Promise.reject({ success: false, error: 'Invalid login credentials' });
  }

  // Business Logic
  return axiosShared.post(API_URL.LOGIN, { email, password }).then(
    (res) =>
      res.status >= 400
        ? Promise.reject({
            success: false,
            error: `${res.statusText} ${res.status}`.trim(),
          })
        : Promise.resolve({ success: true }),
    (err) =>
      Promise.reject({
        success: false,
        error: `${err}`.trim(),
      })
  );
}

export async function apiRefreshToken() {
  // Guard
  if (!isLoggedIn()) {
    return;
  }

  await axiosShared.get(API_URL.REFRESH_TOKEN).then(
    () => {
      console.log('Axios refreshed JWT');
    },
    (e) => {
      console.error('Axios refresh JWT', e);
    }
  );
}
//#endregion

//#region Traditional JWT via storage
async function obtainJWTTokenNoCookie(action: 'login' | 'refresh', postBody: any): Promise<ApiResponse<any>> {
  if (postBody == null) {
    return Promise.reject({ success: false });
  }

  return axiosShared.post(action == 'login' ? API_URL.LOGIN_NOCOOKIE : API_URL.REFRESH_NOCOOKIE, postBody).then(
    (res) => {
      if (res.status >= 400) {
        return Promise.reject({
          success: false,
          error: `${res.statusText} ${res.status}`.trim(),
        });
      }

      // Save JWT token into local storage
      const { succeeded, errors, data } = res.data;

      if (errors || succeeded == false) {
        return Promise.reject({
          success: false,
          error: errors,
        });
      }

      let jwtT = data as JWTToken;
      let saveFlag: boolean = setUserToken(jwtT);

      return Promise.resolve({
        success: saveFlag,
        data: data,
      });
    },
    (err) =>
      Promise.reject({
        success: false,
        error: `${err}`.trim(),
      })
  );
}

export async function apiLoginNoCookie(email: string, password: string): Promise<ApiResponse<any>> {
  // Guard
  if (email === '' || password === '') {
    return Promise.reject({ success: false, error: 'Invalid login credentials' });
  }

  return obtainJWTTokenNoCookie('login', { email, password });
}
//#endregion

//#region User Accounts Response -------
export type UserAccountsResponse = {
  userId: string;
  userAccounts: UserAccountStruct[];
};

export type UserAccountStruct = {
  id: string;
  accessAllowed: boolean;
  accessValidTo: string; // "2044-02-06T00:00:00"
  businessName: string;
  tradingName: string;
  type: string; // "Digital Technology Company",
  // "telephone"     : "44701327831577",
  // "email"         : "support@seamlessdigital.com",
  // "website"       : "https://www.seamlessdigital.com",
  // "createdBy"     : "00000000-0000-0000-0000-000000000000",
  // "createdOn"     : "0001-01-01T00:00:00",
  // "lastModifiedBy": "00000000-0000-0000-0000-000000000000",
  // "lastModifiedOn": null
};

export async function apiGetUserAccounts(userId: string | number): Promise<Error | UserAccountStruct[]> {
  // Guard
  const uid = userId;

  // Business logic
  return axiosShared
    .get(API_URL.USER_ACCOUNTS + uid)
    .then(
      (res: AxiosResponse) => {
        // console.log(res)

        if (res.status >= 400) {
          return Promise.reject(new Error(`${res.statusText} ${res.status}`.trim()));
        }

        const d = res.data; // as UserAccountsResponse
        const dd: UserAccountStruct[] = d.data.userAccounts;
        console.log('Api', d, dd);

        return Promise.resolve(dd);
      },
      (err) => Promise.reject(err)
    )
    .catch((err: AxiosError) => {
      // console.error(err)

      return Promise.reject(new Error(err.message));
    });
}
//#endregion
