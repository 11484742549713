'use client';

import { usePathname, useSearchParams } from 'next/navigation';
import { useRouter } from 'next/navigation';
import { useEffect, useState } from 'react';
import SDAlert from '../components/common/Alert';

export default function TimeoutWarning() {
  const pathname = usePathname();
  const { replace } = useRouter();
  const searchParams = useSearchParams();
  const altVal = searchParams.get('alt');
  const errorMsg = searchParams.get('msg');

  const [alertName, setAlertName] = useState<string | null>(altVal);
  const [customErrorMsg, setCustomErrorMsg] = useState<string | null>(errorMsg);

  const getAlertText = (a: string | null, type: 'title' | 'desc'): string | null => {
    switch (a) {
      case 'timeout':
        return type == 'title'
          ? 'Session timeout'
          : 'You have been logged out because of session timeout, please login again.';
      case 'kicked':
        return type == 'title'
          ? 'You have been logged out'
          : "Your account was accessed from another browser or device. If this wasn't you, please change your password and contact support.";
      default:
        return null;
    }
  };

  useEffect(() => {
    replace(`${pathname}`);
  }, [replace, pathname]);

  if (alertName == null && customErrorMsg == null) {
    return <></>;
  }

  return (
    <div className='fixed left-auto right-0 top-20 flex max-w-lg flex-col items-stretch justify-end px-2 md:right-5 md:top-12'>
      <SDAlert
        idx={0}
        isEnabled={(alertName ?? customErrorMsg) != null}
        title={getAlertText(alertName, 'title')}
        message={customErrorMsg ?? getAlertText(alertName, 'desc')}
      >
        <div className='mt-2 flex flex-row-reverse'>
          <button
            type='button'
            className='rounded bg-blue-500 px-4 py-1.5 text-white shadow'
            onClick={() => {
              setAlertName(null);
              setCustomErrorMsg(null);
            }}
          >
            OK
          </button>
        </div>
      </SDAlert>
    </div>
  );
}
