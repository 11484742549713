'use client';
import { useCallback } from 'react';
import {
  getAutoJWTRefreshIntervalID,
  setAutoJWTRefreshIntervalID,
  removeAutoJWTRefreshIntervalID,
  isLoggedIn,
  getBearToken,
  JWTStorageKey,
  JWTToken,
  setUserToken,
  isAccessTokenExpired,
} from '@/app/lib/storageCommon';
import axiosShared, { API_URL, SITE_MAP } from '@/app/api/api';
import { getCookie } from 'cookies-next';
import { useRouter } from 'next/navigation';

const useAutoRefreshToken = () => {
  // [DEV] Logging switch
  const debugLog: boolean = false;

  const { push } = useRouter();


  const stopBGAutoRefreshTokenUpdateFn = useCallback(() => {

    const iid = getAutoJWTRefreshIntervalID();
    debugLog && console.log('Fn: Stop JWT Auto Refresh', iid);

    if (iid) {
      clearInterval(iid);
      removeAutoJWTRefreshIntervalID();

      debugLog && console.log('Interval stopped X', iid);
    }

  }, [debugLog]);


  const startBGAutoRefreshTokenUpdateFn = useCallback(() => {

    if (getCookie('ui') === undefined || isAccessTokenExpired()) {
      debugLog && console.warn('Error: Fail to start JWT auto refresh because not yet logged-in');
      stopBGAutoRefreshTokenUpdateFn();
      return;
    }

    const iid = getAutoJWTRefreshIntervalID();
    debugLog && console.log('Fn: Start JWT Auto Refresh', iid);
    if (iid) {
      stopBGAutoRefreshTokenUpdateFn();
    }

    const nodeInterval: NodeJS.Timeout = setInterval(
      async () => {
        debugLog &&
          console.info(
            'Perform JWT refresh ()',
            'ui',
            getCookie('ui'),
            'isLoggedIn',
            isLoggedIn(),
            'get IntervalID',
            getAutoJWTRefreshIntervalID(),
            'res.status'
          );

        if (!isLoggedIn()) {
          debugLog && console.error('AutoRefresh: isLoggedIn() returned false');
          stopBGAutoRefreshTokenUpdateFn();

          push(SITE_MAP.SESSION_TIMEOUT);
        }

        if (process.env.NEXT_PUBLIC_J_METHOD_FLAG == 'true') {
          await axiosShared
            .post(API_URL.REFRESH_NOCOOKIE, {
              accessToken: getBearToken(false),
              refreshToken: getCookie(JWTStorageKey.REFRESH_TOKEN),
            })
            .then(
              (res) => {
                if (res.status >= 400) {
                  stopBGAutoRefreshTokenUpdateFn();
                }

                const { succeeded, errors, data } = res.data;

                if (errors || succeeded == false) {
                  stopBGAutoRefreshTokenUpdateFn();

                  debugLog && console.error('Refresh token failed:', errors);

                  push(SITE_MAP.KICKED_OUT);
                }

                if (process.env.NEXT_PUBLIC_J_METHOD_FLAG == 'true') {
                  const t = data as JWTToken;
                  if (!setUserToken(t)) {
                    stopBGAutoRefreshTokenUpdateFn();
                    push(SITE_MAP.KICKED_OUT);
                  }
                }
              },
              (e) => {
                console.error(e);
                stopBGAutoRefreshTokenUpdateFn();
              }
            );
        } else {
          await axiosShared.get(API_URL.REFRESH_TOKEN).then(
            (res) => {
              if (res.status >= 400) {
                stopBGAutoRefreshTokenUpdateFn();
              }
            },
            (e) => {
              // console.error(e);
              stopBGAutoRefreshTokenUpdateFn();
            }
          );
        }
      },
      parseInt(process.env.NEXT_PUBLIC_REFRESH_INTERVAL ?? '' + 15 * 60 * 1000)
    );

    setAutoJWTRefreshIntervalID(nodeInterval);
    debugLog && console.log('Interval started ->', nodeInterval);

  }, [debugLog, push, stopBGAutoRefreshTokenUpdateFn]);
  // };

  // useEffect(() => {

  //   debugLog && console.log("useAutoRefreshToken useEffect");
  //   startBGAutoRefreshTokenUpdateFn();

  //   return () => {
  //     debugLog && console.log("useAutoRefreshToken useEffect return");
  //     stopBGAutoRefreshTokenUpdateFn();
  //   }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return [startBGAutoRefreshTokenUpdateFn, stopBGAutoRefreshTokenUpdateFn];
};
export default useAutoRefreshToken;
