'use client';

import { useEffect, useState, useCallback } from 'react';
import { useRouter } from 'next/navigation';
import LoginForm from '@/app/login/loginForm';
import TimeoutWarning from './timeoutWarning';
import { SITE_MAP } from '@/app/api/api';
import { isLoggedIn } from '@/app/lib/storageCommon';

export default function LoginWrapper() {
  const { push } = useRouter();

  const [isAuthenticated, setAuthenticated] = useState(false);

  let loginSuccessFn = useCallback(() => push(SITE_MAP.ACCOUNT_SELECTION), [push]);
  const loginFailFn = useCallback(() => push(SITE_MAP.KICKED_OUT), [push]);

  const greenLight = isLoggedIn();
  useEffect(() => {
    setAuthenticated( greenLight );

    (greenLight) && setTimeout(() => {
      loginSuccessFn();
    }, 2000);
  }, [loginSuccessFn, greenLight]);


  return (
    <>
      <div
        className={`exHFooter flex w-full items-center justify-center bg-[url('/wallpaper/wallpaper.jpg')] bg-cover`}
      >
        <LoginForm completionHandler={loginSuccessFn} errorHandler={loginFailFn} disabled={isAuthenticated} />
      </div>
      <TimeoutWarning />
    </>
  );
}
