'use client';

import { useState } from 'react';
import LoginEmailInput from '@/app/components/common/LoginEmailInput';
import LoginPwdInput from '@/app/components/common/LoginPwdInput';
import { apiLoginNoCookie, apiLoginWithCookie } from '@/app/api/apiFn';
import useAutoRefreshToken from '../hooks/useAutoRefreshToken';

import { Button } from '@nextui-org/react';
import { JWTToken, setUserToken } from '../lib/storageCommon';

const LoginForm = ({
  completionHandler,
  errorHandler,
  disabled = false,
}: {
  completionHandler: () => void;
  errorHandler: (err: string | Error) => void;
  disabled: boolean;
}) => {
  // Form data
  const [validEmail, setValidEmail] = useState<boolean>(false);
  const [validPwd, setValidPwd] = useState<boolean>(false);
  const [email, setEmailTxt] = useState<string | null>(null);
  const [password, setPwdTxt] = useState<string | null>(null);
  // Flow control
  const [loginFailedMsg, setLoginFailedMsg] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  // BG Refresh JWT
  const [startJWTAutoRefresh] = useAutoRefreshToken();

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    // UI Reset
    setLoginFailedMsg(null);
    setIsSubmitting(true);

    const l =
      process.env.NEXT_PUBLIC_J_METHOD_FLAG == 'true'
        ? await apiLoginNoCookie(email ?? '', password ?? '') // Traditional mode
        : await apiLoginWithCookie(email ?? '', password ?? ''); // Cookie mode

    // console.log("j method", process.env.NEXT_PUBLIC_J_METHOD_FLAG, process.env.NEXT_PUBLIC_J_METHOD_FLAG == "false", process.env.NEXT_PUBLIC_J_METHOD_FLAG == "true")

    if (!l.success) {
      setLoginFailedMsg(`Login failed, please check and try again.`);
      setIsSubmitting(false);
      return;
    }

    // Start auto refresh
    if (process.env.NEXT_PUBLIC_J_METHOD_FLAG == 'true') {
      const t = l.data as JWTToken;
      if (!setUserToken(t)) {
        errorHandler('Save token failed');
      }
    }

    startJWTAutoRefresh();

    // ------------
    // Success Login and continue
    completionHandler();

    // Cleanup ----
    setIsSubmitting(false);
  };

  return (
    <div className={`relative flex w-3/4 max-w-sm flex-col rounded-xl bg-white p-6 shadow overflow-hidden`}>
      <form noValidate onSubmit={handleSubmit} className='flex flex-col gap-y-4'>
        <LoginEmailInput
          disabled={disabled}
          labelText='Login email'
          onChangeValidation={(validFlag: boolean, inputTxt: string) => {
            // console.log("id", inputTxt, validFlag);
            setValidEmail(validFlag);
            setEmailTxt(inputTxt);
          }}
        />

        <LoginPwdInput
          disabled={disabled}
          labelText='Password'
          onChangeValidation={(validFlag: boolean, inputTxt: string) => {
            // console.log("pwd", inputTxt, validFlag);
            setValidPwd(validFlag);
            setPwdTxt(inputTxt);
          }}
        />

        {loginFailedMsg && (
          <div className={`mt-2 rounded bg-rose-100 px-3 py-1.5 text-red-800 shadow`}>{loginFailedMsg}</div>
        )}

        <div className='flex flex-row-reverse items-center justify-between gap-2'>
          <Button color='primary' isLoading={isSubmitting} isDisabled={!disabled && !(validEmail && validPwd)} type='submit'>
            Login
          </Button>
        </div>
        {/* <button type="submit" 
          disabled={!(validEmail && validPwd) || isSubmitting}
          className={`bg-sky-600 text-slate-100 font-normal py-2 px-8 mt-4 self-end rounded
            hover:bg-sky-700 
            active:bg-sky-400 
            disabled:bg-slate-400
          `}
        >
          Login
        </button> */}
      </form>
      {disabled && <div className={`absolute inset-x-0 inset-y-0 bg-black/50`} onClick={() =>{}}></div>}
    </div>
  );
};
export default LoginForm;
